import { useState, Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Sidebar from "../components/Sidebar";
import Box from "@mui/material/Box";
import { API_URL, alertMessage } from "../actions/auth";
import DeleteIcon from "@mui/icons-material/Delete";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { DatePicker } from "@mui/x-date-pickers";
import { tr } from "date-fns/locale";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import moment from "moment";
import AddIcon from "@mui/icons-material/Add";
import Fab from "@mui/material/Fab";
import Snackbar from "@mui/material/Snackbar";
import Card from "@mui/material/Card";
import NotificationsIcon from "@mui/icons-material/Notifications";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Drawer from "@mui/material/Drawer";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { themeStyle } from "../components/Components";
function makeid(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function Vacancies() {
  const dispatch = useDispatch();
  function createAlert(type, title, message) {
    console.log(type, title, message);
    dispatch(alertMessage(type, title, message));
  }
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [toggleDrawer, setToggleDrawer] = useState(false);

  const params = new URLSearchParams(window.location.search);
  const state = useSelector((state) => state);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [select, setSelect] = useState("Vacancy");
  const [vacancyName, setVacancyName] = useState("");
  const [vacancyLocation, setVacancyLocation] = useState("");
  const [vacancyDescription, setVacancyDescription] = useState("");

  const [open, setOpen] = useState(false);
  const [id, setId] = useState("");
  const [type, setType] = useState("");

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelect = (value) => {
    setSelect(value);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handlePageSelect = (event) => {
    setSelectedPage(event.currentTarget.value);
  };

  const colorList = ["#a1d99b", "#9ecae1", "#fdae6b"];

  const [selectedPage, setSelectedPage] = useState(
    params.get("tab") ? params.get("tab") : "VACANCIES"
  );
  const [isLoading, setIsLoading] = useState(true);
  const [vacancies, setVacancies] = useState([]);

  function getData() {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "Content-Type": "application/json",
      },
    };
    body["email"] = state.auth.user.email;
    body = JSON.stringify(body);

    axios.post(API_URL + "get_vacancies", body, config).then((res) => {
      setVacancies(res.data);
      setIsLoading(false);
    });
  }

  useEffect(() => {
    if (state.auth.isLoading) {
      dispatch({ type: "ENTRY_PAGE", payload: window.location.pathname });
      navigate("/", { replace: true });
    } else {
      getData();
    }
  }, []);

  if (state.auth.refresh == "REFRESH_DATA") {
    getData();
    dispatch({ type: "REFRESH_DATA", payload: null });
  }

  function deleteVacancy() {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        "Content-Type": "application/json",
      },
    };
    body["id"] = id;
    body = JSON.stringify(body);

    axios
      .post(API_URL + "delete_vacancy", body, config)
      .then((res) => {
        getData();
        handleClose();
        createAlert("SUCCESS", "Success.", "Vacancy deleted.");
      })
      .catch((err) => {
        createAlert("ERROR", "Error", "An error occured.");
      });
  }

  function addVacancy() {
    setIsLoading(true);
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "Content-Type": "application/json",
      },
    };

    body["id"] = makeid(5);
    body["type"] = select;
    body["date"] = moment(selectedDate).format("DD-MM-YYYY");
    body["name"] = vacancyName;
    body["location"] = vacancyLocation;
    body["description"] = vacancyDescription;

    body = JSON.stringify(body);

    axios
      .post(API_URL + "add_vacancy", body, config)
      .then((res) => {
        getData();
        createAlert("SUCCESS", "Success.", "Vacancy added.");
        setToggleDrawer(false);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        createAlert("ERROR", "Error", "An error occured.");
      });
  }

  function getContent() {
    return (
      <Grid container spacing={1.5}>
        {vacancies.length == 0 ? (
          <div
            style={{
              marginTop: "200px",
              height: "100px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div style={{ textAlign: "center" }}>
              <Fab
                size="large"
                style={{
                  border: "3px solid #aaa",
                  boxShadow: "none",
                  backgroundColor: "transparent",
                }}
                aria-label="add"
              >
                <PriorityHighIcon
                  style={{
                    color: "grey",
                  }}
                />
              </Fab>
              <br />
              <h4 style={{ color: "grey" }}>No vacancies listed.</h4>
            </div>
          </div>
        ) : (
          ""
        )}
        {vacancies.map((vacancy, index) => (
          <Grid item md={3}>
            <Box
              padding={1}
              borderRadius="5px"
              style={{
                borderRadius: "6px",
                border: "2px solid #d7ecff",
                backgroundColor: "white",
                boxShadow: "none",
                height: "100%",
                marginBottom: "5px",
              }}
            >
              <Box style={{ marginLeft: "5px" }}>
                <Grid container spacing={2} justifyContent="space-between">
                  <Grid item md={10}>
                    <div
                      style={{
                        color: "#084594",
                        fontSize: "19px",
                        fontWeight: "bold",
                      }}
                    >
                      {vacancy["name"]}
                    </div>
                    <div
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                      }}
                    >
                      {moment(vacancy["date"], "DD-MM-YYYY").format(
                        "Do MMMM, YYYY"
                      )}
                    </div>
                    <hr />
                    <div
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        color: themeStyle.primaryColor,
                      }}
                    >
                      {vacancy["location"]}
                    </div>
                    <div
                      style={{
                        margin: "10px 0px 0px 0px",
                        fontSize: "15px",
                      }}
                    >
                      {vacancy["description"]}
                    </div>
                  </Grid>
                  <Grid item md={2}>
                    <Button
                      style={{
                        borderRadius: "50px",
                        marginTop: "-10px",

                        height: "50px",
                        width: "50px",
                      }}
                      onClick={() => {
                        setId(vacancy["id"]);
                        setOpen(true);
                      }}
                    >
                      <DeleteIcon
                        style={{
                          color: "#ff3333",
                        }}
                      />
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    );
  }

  if (!state.auth.isLoading) {
    if (state.auth.isAuthenticated) {
      if (isLoading) {
        return (
          <Fragment>
            <Sidebar />
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                backgroundColor: themeStyle.backgroundColor,
                marginTop: "46vh",
              }}
            >
              <CircularProgress />
            </div>
          </Fragment>
        );
      } else {
        return (
          <Fragment>
            <Drawer
              anchor={"right"}
              open={toggleDrawer}
              onClose={() => setToggleDrawer(false)}
            >
              {isSubmitting ? (
                <div
                  style={{
                    marginTop: "60px",
                    padding: "20px",
                    width: "400px",
                    backgroundColor: themeStyle.backgroundColor,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <CircularProgress />
                  <h3>Uploading in progress...</h3>
                </div>
              ) : (
                <div style={{ padding: "15px", marginTop: "23px" }}>
                  <div
                    style={{
                      marginTop: "45px",
                      color: "#3182bd",

                      fontSize: "20px",
                    }}
                  >
                    Select type
                  </div>
                  <Select
                    value={select}
                    onChange={(e) => handleSelect(e.target.value)}
                    style={{ marginTop: "2px", height: "40px" }}
                    variant={"outlined"}
                    fullWidth
                  >
                    <MenuItem value={"Vacancy"}>Job Opening</MenuItem>
                    <MenuItem value={"Date"}>Internship</MenuItem>
                    {/* <MenuItem value={"Holiday"}>Holiday</MenuItem> */}
                  </Select>

                  <br></br>
                  <br></br>

                  <span
                    style={{
                      color: "#3182bd",
                      fontSize: "20px",
                    }}
                  >
                    Name
                  </span>
                  <TextField
                    style={{ marginTop: "2px" }}
                    value={vacancyName}
                    fullWidth
                    size="small"
                    onChange={(e) => setVacancyName(e.target.value)}
                    placeholder={"Opening Name"}
                    variant="outlined"
                  />
                  <br></br>

                  <div style={{ marginTop: "15px" }}>
                    <span
                      style={{
                        color: "#3182bd",
                        fontSize: "20px",
                      }}
                    >
                      Location
                    </span>
                    <TextField
                      style={{ marginTop: "2px" }}
                      fullWidth
                      value={vacancyLocation}
                      onChange={(e) => setVacancyLocation(e.target.value)}
                      size="small"
                      placeholder="Job Location"
                      variant="outlined"
                    />
                    <div
                      style={{
                        marginTop: "15px",
                        color: "#3182bd",

                        fontSize: "20px",
                      }}
                    >
                      Job Description
                    </div>
                    <TextField
                      style={{ marginTop: "2px" }}
                      multiline
                      value={vacancyDescription}
                      onChange={(e) => setVacancyDescription(e.target.value)}
                      rows={4}
                      fullWidth
                      variant="outlined"
                      placeholder={"Job Description"}
                    />
                    <br></br>
                  </div>

                  <br></br>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                      height: "35px",
                    }}
                  >
                    <Button
                      onClick={() => addVacancy()}
                      fullWidth
                      variant="contained"
                      style={{
                        backgroundColor: "#3182bd",
                        boxShadow: "none",
                      }}
                    >
                      <h4>Add vacancy</h4>
                    </Button>
                  </Box>
                </div>
              )}
            </Drawer>

            <Sidebar
              tabs={
                <Grid container spacing={1.5} justifyContent={"flex-end"}>
                  <Grid item md={4}>
                    {selectedPage == "VACANCIES" ? (
                      <Button
                        fullWidth
                        value={"VACANCIES"}
                        onClick={handlePageSelect}
                        p={2}
                        style={{
                          backgroundColor: "#3182bd",
                          color: "white",
                          height: "30px",
                        }}
                        borderRadius={6}
                      >
                        <h3
                          style={{
                            marginTop: "17px",
                            fontSize: "15px",
                          }}
                        >
                          VACANCIES
                        </h3>
                      </Button>
                    ) : (
                      <Button
                        fullWidth
                        value={"VACANCIES"}
                        onClick={handlePageSelect}
                        p={2}
                        style={{
                          backgroundColor: "#d7ecff",
                          color: "white",
                          height: "30px",
                        }}
                        borderRadius={6}
                      >
                        <h3
                          style={{
                            marginTop: "17px",
                            fontSize: "15px",
                            color: "#084594",
                          }}
                        >
                          VACANCIES
                        </h3>
                      </Button>
                    )}
                  </Grid>
                  <Grid item md={1}>
                    <Button
                      onClick={() => setToggleDrawer(true)}
                      style={{
                        width: "20px",
                        height: "30px",
                        borderRadius: "15px",
                        backgroundColor: "#ff3333",
                        margin: "0px -50px 0px 10px",
                      }}
                    >
                      <AddIcon
                        style={{
                          color: "white",
                        }}
                      />
                    </Button>
                  </Grid>
                </Grid>
              }
            />
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={open}
              onClose={handleClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={open}>
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    backgroundColor: "white",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 400,
                    padding: "20px",
                  }}
                >
                  <div style={{ marginTop: "-25px" }}>
                    <h3>Delete this item?</h3>
                    <span>
                      This action cannot be reversed. It will be deleted
                      forever.
                    </span>
                    <br></br>
                    <br></br>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Button
                        onClick={() => deleteVacancy()}
                        style={{ height: "30px" }}
                        variant="contained"
                      >
                        Yes
                      </Button>
                      <Button
                        style={{ height: "30px", marginLeft: "10px" }}
                        variant="contained"
                        onClick={() => setOpen(false)}
                      >
                        No
                      </Button>
                    </div>
                  </div>
                </div>
              </Fade>
            </Modal>
            <div
              style={{
                padding: "85px 15px 20px 105px",
                backgroundColor: themeStyle.backgroundColor,
                height: "100vh",
              }}
            >
              {getContent()}
            </div>
          </Fragment>
        );
      }
    } else {
      return <Navigate to="/login" />;
    }
  } else {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          backgroundColor: "white",
          marginTop: "46vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
}

export default Vacancies;
