import React, { useEffect, Fragment, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Router, Route, Routes, useNavigate } from "react-router-dom";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Profiles from "./pages/Profiles";
import Medicines from "./pages/Medicines";
import Media from "./pages/Media";
import Reports from "./pages/Reports";
import Loader from "./pages/Loader";
import Logout from "./pages/Logout";
import Oops from "./pages/Oops";
import Register from "./pages/Register";
import { loadUser } from "./actions/auth";
import Support from "./pages/Support";
import Vacancies from "./pages/Vacancies";

function App() {
  const dispatch = useDispatch();
  const params = new URLSearchParams(window.location.search);
  const state = useSelector((state) => state);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch({ type: "USER_LOADING" });
    dispatch(loadUser());
  }, []);

  if (state.auth.loginFailed) {
    navigate("/login", { replace: true });
    dispatch({ type: "RESET_REDIRECT_LOGIN" });
  }

  return (
    <Routes>
      <Route exact path="/" element={<Loader />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/profiles" element={<Profiles />} />
      <Route path="/login" element={<Login />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/register" element={<Register />} />
      <Route path="/support" element={<Support />} />
      <Route path="/vacancies" element={<Vacancies />} />
      <Route path="*" element={<Oops />} />
    </Routes>
  );
}

export default App;
