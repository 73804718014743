import React, { useEffect, useState, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import moment from "moment";
import QRCode from "qrcode";

import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Sidebar from "../components/Sidebar";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import { alertMessage } from "../actions/auth";
import { Bar } from "react-chartjs-2";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TextField from "@mui/material/TextField";
import Avatar from "@mui/material/Avatar";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { Chart } from "chart.js/auto";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Fab } from "@mui/material";
import Fade from "@mui/material/Fade";
import IconButton from "@mui/material/IconButton";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Checkbox from "@mui/material/Checkbox";
import DownloadIcon from "@mui/icons-material/Download";
import QrCodeIcon from "@mui/icons-material/QrCode";

import { API_URL } from "../actions/auth";

import { themeStyle, themeStyleDark } from "../components/Components";

function Profiles() {
  const dispatch = useDispatch();
  function createAlert(type, title, message) {
    dispatch(alertMessage(type, title, message));
  }
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const state = useSelector((state) => state);
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [ogUsers, setOGusers] = useState([]);
  const [random, setRandom] = useState(0);
  const [search, setSearch] = useState("");
  const [profile, setProfile] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);

  function handleSearch(text) {
    setSearch(text);
    if (text.length == 0) {
      setUsers(ogUsers);
      setSearch("");
      setRandom(makeid(5));
    } else {
      var tempUsers = [];

      for (var i = 0; i < ogUsers.length; i++) {
        if (
          ogUsers[i]["full_name"].includes(text) ||
          ogUsers[i]["email"].includes(text)
        ) {
          tempUsers.push(ogUsers[i]);
        }
      }
      setCurrentPage(1);
      setUsers(tempUsers);
      setRandom(makeid(5));
    }
  }

  function makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  function handleCurrentPage(val) {
    var tempUsers = Array.from(ogUsers);
    setSearch("");
    setCurrentPage(val);
    setUsers(tempUsers.splice((val - 1) * 25, 25));
  }

  function getData() {
    var body = {
      uid: state.auth.user.uid,
    };
    const config = {
      headers: {
        Authorization: state.auth.token,
        "Content-Type": "application/json",
      },
    };
    axios.post(API_URL + "get_users", body, config).then((res) => {
      setOGusers(res.data);
      setUsers(res.data.splice(0, 25));
      setIsLoading(false);
    });
  }

  function changeAccess(value, uid) {
    var body = {
      uid: uid,
      value: value,
    };
    const config = {
      headers: {
        Authorization: state.auth.token,
        "Content-Type": "application/json",
      },
    };
    axios.post(API_URL + "change_access", body, config).then((res) => {
      setIsLoading(true);
      getData();
    });
  }

  function changeAccess2(value, uid) {
    var body = {
      uid: uid,
      value: value,
    };
    const config = {
      headers: {
        Authorization: state.auth.token,
        "Content-Type": "application/json",
      },
    };
    axios.post(API_URL + "change_access2", body, config).then((res) => {
      setIsLoading(true);
      getData();
    });
  }

  useEffect(() => {
    if (state.auth.isLoading) {
      dispatch({ type: "ENTRY_PAGE", payload: window.location.pathname });
      navigate("/", { replace: true });
    } else {
      getData();
    }
  }, []);

  if (!state.auth.isLoading) {
    if (state.auth.isAuthenticated) {
      if (isLoading) {
        return (
          <Fragment>
            <Sidebar />
            <div
              style={{
                maxWidth: "88vw",
                marginTop: "45vh",
                marginLeft: "8vw",
                backgroundColor:
                  state.auth.theme == "dark"
                    ? themeStyleDark.backgroundColor
                    : themeStyle.backgroundColor,
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </div>
          </Fragment>
        );
      } else {
        return (
          <Fragment>
            <Sidebar />
            {users.length == 0 ? (
              <div
                style={{
                  padding: "100px 50px 90px 115px",
                  backgroundColor:
                    state.auth.theme == "dark"
                      ? themeStyleDark.backgroundColor
                      : themeStyle.backgroundColor,
                  minHeight: "100vh",
                  width: "100vw",
                  fontSize: "30px",
                }}
              >
                No Users yet
              </div>
            ) : (
              <div
                style={{
                  padding: "100px 50px 90px 115px",
                  backgroundColor:
                    state.auth.theme == "dark"
                      ? themeStyleDark.backgroundColor
                      : themeStyle.backgroundColor,
                  minHeight: "100vh",
                  width: "100vw",
                }}
              >
                <TextField
                  fullWidth
                  id="outlined-basic"
                  placeholder="Search User / Email"
                  variant="outlined"
                  value={search}
                  size="small"
                  sx={{
                    input: {
                      fontSize: "24px",
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryColor
                          : themeStyle.primaryColor,
                    },
                  }}
                  style={{
                    height: "55px",
                    borderRadius: "10px",
                    border:
                      state.auth.theme == "dark"
                        ? "2px solid " + themeStyleDark.primaryColor
                        : "2px solid " + themeStyle.primaryColor,
                    backgroundColor:
                      state.auth.theme == "dark"
                        ? themeStyleDark.primaryCardColor
                        : themeStyle.primaryCardColor,
                    boxShadow: "0px 0px 10px #ccc",

                    marginBottom: "30px",
                  }}
                  onChange={(e) => handleSearch(e.target.value)}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    marginBottom: "20px",
                  }}
                >
                  <Grid container spacing={3} justifyContent={"flex-end"}>
                    <Grid item md={12} lg={8}>
                      {Array.from(
                        Array(Math.ceil(ogUsers.length / 25)).keys()
                      ).map((temp, index) => (
                        <div
                          style={{
                            cursor: "pointer",
                            margin: "3px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "25px",
                            height: "25px",
                            borderRadius: "30px",
                            backgroundColor:
                              state.auth.theme == "dark"
                                ? themeStyleDark.primaryColor
                                : themeStyle.primaryColor,
                          }}
                          onClick={() => {
                            handleCurrentPage(index + 1);
                          }}
                        >
                          {index + 1}
                        </div>
                      ))}
                      <div
                        style={{
                          backgroundColor:
                            state.auth.theme == "dark"
                              ? themeStyleDark.primaryCardColor
                              : themeStyle.primaryCardColor,
                          borderRadius: "10px",
                          padding: "15px",
                          boxShadow: "0px 0px 10px #ccc",
                        }}
                      >
                        <Grid
                          container
                          spacing={2}
                          style={{
                            justifyContent: "flex-start",
                            textAlign: "left",
                            color:
                              state.auth.theme == "dark"
                                ? themeStyleDark.primaryColor
                                : themeStyle.primaryColor,
                            paddingBottom: "20px",
                            borderBottom:
                              state.auth.theme == "dark"
                                ? "1px solid" + themeStyleDark.primaryColor
                                : "1px solid" + themeStyle.primaryColor,
                          }}
                        >
                          <Grid item md={2}>
                            Phone Number
                          </Grid>
                          <Grid item md={2} style={{ textAlign: "left" }}>
                            Full Name
                          </Grid>
                          <Grid item md={2} style={{ textAlign: "left" }}>
                            Account
                          </Grid>
                          <Grid item md={2} style={{ textAlign: "left" }}>
                            Approved
                          </Grid>
                          <Grid item md={2} style={{ textAlign: "left" }}>
                            Rejected
                          </Grid>
                          <Grid item md={2} style={{ textAlign: "left" }}>
                            Plan
                          </Grid>
                        </Grid>
                        {users.map((user, index) => (
                          <Grid
                            onClick={() => setProfile(user)}
                            container
                            spacing={2}
                            style={{
                              color: "black",
                              padding: "15px",
                            }}
                          >
                            <Grid
                              style={{ cursor: "pointer" }}
                              onClick={() => setProfile(user)}
                              item
                              md={2}
                            >
                              {user["phone_number"]}
                            </Grid>
                            <Grid item md={2}>
                              {user["full_name"]}
                            </Grid>

                            <Grid item md={2} style={{ textAlign: "left" }}>
                              {user["is_mr"]
                                ? "MR"
                                : user["is_doctor"]
                                ? "Doctor"
                                : "Hospital"}
                            </Grid>

                            <Grid item md={2} style={{ textAlign: "left" }}>
                              <Checkbox
                                disableRipple={true}
                                labelStyle={{ color: "#FFA500" }}
                                iconStyle={{ fill: "white" }}
                                checked={user["approved"]}
                                onChange={(e) => {
                                  changeAccess(e.target.checked, user["uid"]);
                                }}
                                value={1}
                                style={{
                                  marginTop: "-7px",
                                  transform: "scale(1.2)",
                                  color:
                                    state.auth.theme == "dark"
                                      ? themeStyleDark.primaryColor
                                      : themeStyle.primaryColor,
                                }}
                              />{" "}
                            </Grid>
                            <Grid item md={2} style={{ textAlign: "left" }}>
                              <Checkbox
                                disableRipple={true}
                                labelStyle={{ color: "#FFA500" }}
                                iconStyle={{ fill: "white" }}
                                checked={user["rejected"]}
                                onChange={(e) => {
                                  changeAccess2(e.target.checked, user["uid"]);
                                }}
                                value={1}
                                style={{
                                  marginTop: "-7px",
                                  transform: "scale(1.2)",
                                  color:
                                    state.auth.theme == "dark"
                                      ? themeStyleDark.primaryColor
                                      : themeStyle.primaryColor,
                                }}
                              />{" "}
                            </Grid>
                            <Grid item md={2}>
                              {user["subscription_tier"]}
                            </Grid>
                          </Grid>
                        ))}
                      </div>
                    </Grid>
                    <Grid item md={12} lg={4}>
                      {profile != null ? (
                        <div
                          style={{
                            backgroundColor:
                              state.auth.theme == "dark"
                                ? themeStyleDark.primaryCardColor
                                : themeStyle.primaryCardColor,
                            borderRadius: "10px",
                            padding: "15px",
                            height: "100%",
                            boxShadow: "0px 0px 10px #ccc",
                          }}
                        >
                          <div
                            style={{
                              marginBottom: "15px",
                              color:
                                state.auth.theme == "dark"
                                  ? themeStyleDark.primaryColor
                                  : themeStyle.primaryColor,
                            }}
                          >
                            Selected Profile
                          </div>
                          <b
                            style={{ color: profile.changed ? "red" : "black" }}
                          >
                            Changed:
                          </b>{" "}
                          <b
                            style={{ color: profile.changed ? "red" : "black" }}
                          >
                            {profile.changed ? " NEW DATA ADDED" : " UNCHANGED"}
                          </b>
                          <br />
                          <br />
                          <b>UID:</b> {" " + profile.uid}
                          <br />
                          <br />
                          <b>Phone number:</b> {" " + profile.phone_number}
                          <br />
                          <b>Email:</b> {" " + profile.email}
                          <br />
                          <b>Full Name:</b> {" " + profile.full_name}
                          <br />
                          <br />
                          <b>Location:</b>{" "}
                          {" " +
                            Object.entries(JSON.parse(profile.location)).map(
                              ([k, v]) => {
                                return k + ": " + v + "\n \n";
                              }
                            )}
                          <br />
                          <br />
                          <b>Profile Status:</b>{" "}
                          {" " + profile.initiated &&
                          profile.initiated2 == "PROFILE COMPLETE"
                            ? profile.initiated2
                              ? "ID NOT UPLOADED"
                              : "INCOMPLETE"
                            : "INCOMPLETE"}
                          <br />
                          <br />
                          <b>
                            {profile.is_mr
                              ? "Company Name:"
                              : profile.is_doctor
                              ? "Clinic Name and Address:"
                              : "Hospital Name and Address:"}
                          </b>
                          {" " + profile.workplace}
                          <br />
                          {profile.is_doctor ? (
                            <b>Division: {" " + profile.division}</b>
                          ) : (
                            ""
                          )}
                          <br />
                          <b>Specializations:</b>{" "}
                          {" " + profile.specializations == null
                            ? ""
                            : profile.specializations
                                .replaceAll('"', "")
                                .replaceAll("[", "")
                                .replaceAll("]", "")}
                          <br />
                          <br />
                          <b>Notice:</b> {" " + profile.notice}
                          <br />
                          <b>Information:</b> {" " + profile.information}
                          <br />
                          <b>Timings:</b>
                          {" " + profile.timings}
                          <br />
                          <br />
                          <b>PROFILE PIC:</b>
                          <br />
                          <img
                            style={{ width: "100%" }}
                            src={API_URL + "media/" + profile.uid + ".jpg"}
                          />
                          <br />
                          <br />
                          <b>ID CARD:</b>
                          <br />
                          <img
                            style={{ width: "100%" }}
                            src={API_URL + "media/" + profile.uid + "_id.jpg"}
                          />
                          <br />
                          <br />
                          <b>GOVT PHOTO ID:</b>
                          <br />
                          <img
                            style={{ width: "100%" }}
                            src={API_URL + "media/" + profile.uid + "_card.jpg"}
                          />
                        </div>
                      ) : (
                        "CLICK ON A PROFILE TO SELECT"
                      )}
                    </Grid>
                  </Grid>
                </div>
              </div>
            )}
          </Fragment>
        );
      }
    } else {
      return <Navigate to="/login" />;
    }
  } else {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          backgroundColor:
            state.auth.theme == "dark"
              ? themeStyleDark.backgroundColor
              : themeStyle.backgroundColor,
          marginTop: "46vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
}

export default Profiles;
