import React, { useEffect, useState, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import moment from "moment";

import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Sidebar from "../components/Sidebar";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import { alertMessage } from "../actions/auth";
import { Doughnut } from "react-chartjs-2";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TextField from "@mui/material/TextField";
import Avatar from "@mui/material/Avatar";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { Chart } from "chart.js/auto";
import Select from "@mui/material/Select";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  BarElement,
  RadialLinearScale,
  ArcElement,
  Legend,
  scales,
} from "chart.js";
import MenuItem from "@mui/material/MenuItem";
import { Fab } from "@mui/material";
import Fade from "@mui/material/Fade";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { Line } from "react-chartjs-2";
import { refreshData } from "../actions/auth";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import PersonIcon from "@mui/icons-material/Person";
import { API_URL } from "../actions/auth";
import SchoolIcon from "@mui/icons-material/School";
import { themeStyle, themeStyleDark } from "../components/Components";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import PeopleAlt from "@mui/icons-material/PeopleAlt";
import Vaccines from "@mui/icons-material/Vaccines";
import ChartDataLabels from "chartjs-plugin-datalabels";
import zoomPlugin from "chartjs-plugin-zoom";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  BarElement,
  Filler,
  Legend,
  RadialLinearScale,
  ArcElement,
  zoomPlugin
);

function Dashboard() {
  const dispatch = useDispatch();
  function createAlert(type, title, message) {
    dispatch(alertMessage(type, title, message));
  }
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const state = useSelector((state) => state);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedNoti, setSelectedNoti] = useState("EVERYONE");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [totalUsers, setTotalUsers] = useState(0);
  const [paidUsers, setPaidUsers] = useState(0);
  const [freeUsers, setFreeUsers] = useState(0);
  const [mrs, setMrs] = useState(0);
  const [doctors, setDoctors] = useState(0);
  const [hospitals, setHospitals] = useState(0);

  function sendNotification() {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "Content-Type": "application/json",
      },
    };
    body["send_to"] = selectedNoti;
    body["title"] = subject;
    body["message"] = message;

    axios.post(API_URL + "send_notification", body, config).then((res) => {
      createAlert("SUCCESS", "Sent.", "Notification sent!");
    });
  }

  const graphState = {
    dataLine: {
      labels: [
        "JAN",
        "FEB",
        "MAR",
        "APR",
        "MAY",
        "JUN",
        "JUL",
        "AUG",
        "SEP",
        "OCT",
        "NOV",
        "DEC",
      ],
      datasets: [
        {
          label: "Users per month",
          fill: false,
          lineTension: 0.5,
          borderRadius: 0,
          borderSkipped: false,
          backgroundColor: "rgba(8, 160, 69, 0.8)",
          borderColor: "rgba(8, 160, 69, 0.8)",
          pointRadius: 0,
          borderDash: [],
          borderDashOffset: 0.0,
          data: [66, 44, 66, 55, 44, 45, 77, 55, 66, 66, 55, 77],
          barThickness: 30,
          borderWidth: 2,
        },
        {
          label: "Sales per month",
          fill: false,
          lineTension: 0.5,
          borderRadius: 0,
          borderSkipped: false,
          backgroundColor: "rgba(0, 101, 194, 0.8)",
          borderColor: "rgba(0, 101, 194, 0.8)",

          borderDash: [],
          borderWidth: 2,
          borderDashOffset: 0.0,
          data: [55, 50, 50, 81, 56, 55, 65, 59, 80, 44, 56, 63],
          barThickness: 30,
          pointRadius: 0,
        },
      ],
    },
  };

  const [selectedPage, setSelectedPage] = useState(
    params.get("tab") ? params.get("tab") : "HOME"
  );

  function getData() {
    const config = {
      headers: {
        Authorization: state.auth.token,
        "Content-Type": "application/json",
      },
    };
    axios.get(API_URL + "get_dashboard", config).then((res) => {
      setTotalUsers(res.data["total_users"]);
      setPaidUsers(res.data["paid_users"]);
      setFreeUsers(res.data["free_users"]);
      setMrs(res.data["mrs"]);
      setDoctors(res.data["doctors"]);
      setHospitals(res.data["hospitals"]);
      setIsLoading(false);
    });
  }

  const handleNotiSelect = (event) => {
    setSelectedNoti(event.currentTarget.value);
  };

  const handlePageSelect = (event) => {
    setSelectedPage(event.currentTarget.value);
  };

  useEffect(() => {
    if (state.auth.isLoading) {
      dispatch({ type: "ENTRY_PAGE", payload: window.location.pathname });
      navigate("/", { replace: true });
    } else {
      getData();
    }
  }, []);

  if (state.auth.refresh.includes("dashboard")) {
    getData();
    dispatch(refreshData("dashboard"));
  }

  if (!state.auth.isLoading) {
    if (state.auth.isAuthenticated) {
      if (isLoading) {
        return (
          <Fragment>
            <Sidebar />
            <div
              style={{
                maxWidth: "88vw",
                marginTop: "45vh",
                marginLeft: "8vw",
                backgroundColor:
                  state.auth.theme == "dark"
                    ? themeStyleDark.backgroundColor
                    : themeStyle.backgroundColor,
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </div>
          </Fragment>
        );
      } else {
        return (
          <Fragment>
            <Sidebar
              tabs={
                <Grid container spacing={1.5} justifyContent={"flex-end"}>
                  <Grid item md={4}>
                    {selectedPage == "HOME" ? (
                      <Button
                        fullWidth
                        value={"HOME"}
                        onClick={handlePageSelect}
                        p={2}
                        style={{
                          backgroundColor:
                            state.auth.theme == "dark"
                              ? themeStyleDark.primaryColor
                              : themeStyle.primaryColor,
                          color: "white",
                          height: "30px",
                        }}
                        borderRadius={6}
                      >
                        <h3
                          style={{
                            marginTop: "17px",
                            fontSize: "15px",
                          }}
                        >
                          HOME
                        </h3>
                      </Button>
                    ) : (
                      <Button
                        fullWidth
                        value={"HOME"}
                        onClick={handlePageSelect}
                        p={2}
                        style={{
                          backgroundColor: "#d7ecff",
                          color: "white",
                          height: "30px",
                        }}
                        borderRadius={6}
                      >
                        <h3
                          style={{
                            marginTop: "17px",
                            fontSize: "15px",
                            color: "#084594",
                          }}
                        >
                          HOME
                        </h3>
                      </Button>
                    )}
                  </Grid>
                  {/* <Grid item md={4}>
                    {selectedPage == "NOTIFICATIONS" ? (
                      <Button
                        fullWidth
                        value="NOTIFICATIONS"
                        onClick={handlePageSelect}
                        p={2}
                        style={{
                          backgroundColor:
                            state.auth.theme == "dark"
                              ? themeStyleDark.primaryColor
                              : themeStyle.primaryColor,
                          color: "white",
                          height: "30px",
                        }}
                        borderRadius={6}
                      >
                        <h3
                          style={{
                            marginTop: "17px",
                            fontSize: "15px",
                          }}
                        >
                          NOTIFICATIONS
                        </h3>
                      </Button>
                    ) : (
                      <Button
                        fullWidth
                        value="NOTIFICATIONS"
                        onClick={handlePageSelect}
                        p={2}
                        style={{
                          backgroundColor: "#d7ecff",
                          color: "white",
                          height: "30px",
                        }}
                        borderRadius={6}
                      >
                        <h3
                          style={{
                            marginTop: "17px",
                            fontSize: "15px",
                            color: "#084594",
                          }}
                        >
                          NOTIFICATIONS
                        </h3>
                      </Button>
                    )}
                  </Grid> */}
                </Grid>
              }
            />
            <div
              style={{
                padding: "110px 100px 90px 165px",
                backgroundColor:
                  state.auth.theme == "dark"
                    ? themeStyleDark.backgroundColor
                    : themeStyle.backgroundColor,
                height: "100vh",
                width: "100vw",
              }}
            >
              <Grid container spacing={3}>
                <Grid item md={4}>
                  <CardActionArea
                    sx={[
                      {
                        height: "12vh",
                        borderRadius: "10px",
                        backgroundColor:
                          state.auth.theme == "dark"
                            ? themeStyleDark.primaryCardColor
                            : themeStyle.primaryCardColor,

                        color:
                          state.auth.theme == "dark"
                            ? themeStyleDark.textColor
                            : themeStyle.textColor,
                        transition: "background-color 0.5s, border-color 0.5s",
                      },
                      (theme) => ({
                        "&& .MuiTouchRipple-child": {
                          backgroundColor:
                            state.auth.theme == "dark"
                              ? themeStyleDark.primaryColor
                              : themeStyle.primaryColor,
                        },
                      }),
                    ]}
                    style={{
                      marginBottom: "20px",
                      boxShadow: "0px 0px 10px #ccc",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        height: "100%",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          flexDirection: "column",
                          width: "20%",
                          backgroundColor:
                            state.auth.theme == "dark"
                              ? themeStyleDark.primaryColor
                              : themeStyle.primaryColor,
                          borderRadius: "10px",
                        }}
                      >
                        <PeopleAlt
                          style={{
                            padding: "10px",
                            color: "white",
                            border:
                              state.auth.theme == "dark"
                                ? "2px solid " + themeStyleDark.primaryColor
                                : "2px solid " + themeStyle.primaryColor,
                            borderRadius: "10px",
                            textAlign: "center",
                            fontSize: "80px",
                            marginTop: "-10%",
                          }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          width: "80%",
                          height: "100%",
                          paddingLeft: "45px",
                        }}
                      >
                        <div
                          style={{
                            textAlign: "left",
                            fontSize: "25px",
                            fontFamily: "Bahnschrift",
                            color: "grey",
                          }}
                        >
                          Total Users
                        </div>
                        <div
                          style={{
                            textAlign: "left",
                            fontSize: "50px",
                            fontWeight: "bold",
                            spacing: "5px",
                            fontFamily: "Figtree",
                          }}
                        >
                          {totalUsers}
                        </div>
                      </div>
                    </div>
                  </CardActionArea>
                </Grid>
                <Grid item md={4}>
                  <CardActionArea
                    sx={[
                      {
                        height: "12vh",
                        borderRadius: "10px",
                        backgroundColor:
                          state.auth.theme == "dark"
                            ? themeStyleDark.primaryCardColor
                            : themeStyle.primaryCardColor,

                        color:
                          state.auth.theme == "dark"
                            ? themeStyleDark.textColor
                            : themeStyle.textColor,
                        transition: "background-color 0.5s, border-color 0.5s",
                      },
                      (theme) => ({
                        "&& .MuiTouchRipple-child": {
                          backgroundColor: "#08A045",
                        },
                      }),
                    ]}
                    style={{
                      marginBottom: "10px",
                      boxShadow: "0px 0px 10px #ccc",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        height: "100%",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          flexDirection: "column",
                          width: "20%",
                          backgroundColor: "#08A045",
                          borderRadius: "10px",
                        }}
                      >
                        <CurrencyRupeeIcon
                          style={{
                            padding: "10px",
                            color: "white",
                            border: "2px solid #08A045",
                            borderRadius: "10px",
                            textAlign: "center",
                            fontSize: "80px",
                            marginTop: "-10%",
                          }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          width: "80%",
                          height: "100%",
                          paddingLeft: "45px",
                        }}
                      >
                        <div
                          style={{
                            textAlign: "left",
                            fontSize: "25px",
                            fontFamily: "Bahnschrift",
                            color: "grey",
                          }}
                        >
                          Paid Users
                        </div>
                        <div
                          style={{
                            textAlign: "left",
                            fontSize: "50px",
                            fontWeight: "bold",
                            spacing: "5px",
                            fontFamily: "Figtree",
                          }}
                        >
                          {paidUsers}
                        </div>
                      </div>
                    </div>
                  </CardActionArea>
                </Grid>
                <Grid item md={4}>
                  <CardActionArea
                    sx={[
                      {
                        height: "12vh",
                        borderRadius: "10px",
                        backgroundColor:
                          state.auth.theme == "dark"
                            ? themeStyleDark.primaryCardColor
                            : themeStyle.primaryCardColor,

                        color:
                          state.auth.theme == "dark"
                            ? themeStyleDark.textColor
                            : themeStyle.textColor,
                        transition: "background-color 0.5s, border-color 0.5s",
                      },
                      (theme) => ({
                        "&& .MuiTouchRipple-child": {
                          backgroundColor:
                            state.auth.theme == "dark"
                              ? themeStyleDark.primaryColor
                              : themeStyle.primaryColor,
                        },
                      }),
                    ]}
                    style={{
                      marginBottom: "10px",
                      boxShadow: "0px 0px 10px #ccc",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        height: "100%",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          flexDirection: "column",
                          width: "20%",
                          backgroundColor: "#756bb1",
                          borderRadius: "10px",
                        }}
                      >
                        <AdsClickIcon
                          style={{
                            padding: "10px",
                            color: "white",
                            border: "2px solid #756bb1",
                            borderRadius: "10px",
                            textAlign: "center",
                            fontSize: "80px",
                            marginTop: "-10%",
                          }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          width: "80%",
                          height: "100%",
                          paddingLeft: "45px",
                        }}
                      >
                        <div
                          style={{
                            textAlign: "left",
                            fontSize: "25px",
                            fontFamily: "Bahnschrift",
                            color: "grey",
                          }}
                        >
                          Free Users
                        </div>
                        <div
                          style={{
                            textAlign: "left",
                            fontSize: "50px",
                            fontWeight: "bold",
                            spacing: "5px",
                            fontFamily: "Figtree",
                          }}
                        >
                          {freeUsers}
                        </div>
                      </div>
                    </div>
                  </CardActionArea>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item md={4}>
                  <CardActionArea
                    sx={[
                      {
                        height: "12vh",
                        borderRadius: "10px",
                        backgroundColor:
                          state.auth.theme == "dark"
                            ? themeStyleDark.primaryCardColor
                            : themeStyle.primaryCardColor,

                        color:
                          state.auth.theme == "dark"
                            ? themeStyleDark.textColor
                            : themeStyle.textColor,
                        transition: "background-color 0.5s, border-color 0.5s",
                      },
                      (theme) => ({
                        "&& .MuiTouchRipple-child": {
                          backgroundColor:
                            state.auth.theme == "dark"
                              ? themeStyleDark.primaryColor
                              : themeStyle.primaryColor,
                        },
                      }),
                    ]}
                    style={{
                      marginBottom: "20px",
                      boxShadow: "0px 0px 10px #ccc",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        height: "100%",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          flexDirection: "column",
                          width: "20%",
                          backgroundColor: "#e6550d",
                          borderRadius: "10px",
                        }}
                      >
                        <HomeRepairServiceIcon
                          style={{
                            padding: "10px",
                            color: "white",
                            border: "2px solid #e6550d",
                            borderRadius: "10px",
                            textAlign: "center",
                            fontSize: "80px",
                            marginTop: "-10%",
                          }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          width: "80%",
                          height: "100%",
                          paddingLeft: "45px",
                        }}
                      >
                        <div
                          style={{
                            textAlign: "left",
                            fontSize: "25px",
                            fontFamily: "Bahnschrift",
                            color: "grey",
                          }}
                        >
                          Representatives
                        </div>
                        <div
                          style={{
                            textAlign: "left",
                            fontSize: "50px",
                            fontWeight: "bold",
                            spacing: "5px",
                            fontFamily: "Figtree",
                          }}
                        >
                          {mrs}
                        </div>
                      </div>
                    </div>
                  </CardActionArea>
                </Grid>
                <Grid item md={4}>
                  <CardActionArea
                    sx={[
                      {
                        height: "12vh",
                        borderRadius: "10px",
                        backgroundColor:
                          state.auth.theme == "dark"
                            ? themeStyleDark.primaryCardColor
                            : themeStyle.primaryCardColor,

                        color:
                          state.auth.theme == "dark"
                            ? themeStyleDark.textColor
                            : themeStyle.textColor,
                        transition: "background-color 0.5s, border-color 0.5s",
                      },
                      (theme) => ({
                        "&& .MuiTouchRipple-child": {
                          backgroundColor: "#08A045",
                        },
                      }),
                    ]}
                    style={{
                      marginBottom: "10px",
                      boxShadow: "0px 0px 10px #ccc",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        height: "100%",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          flexDirection: "column",
                          width: "20%",
                          backgroundColor: "#c51b8a",
                          borderRadius: "10px",
                        }}
                      >
                        <LocalHospitalIcon
                          style={{
                            padding: "10px",
                            color: "white",
                            border: "2px solid #c51b8a",
                            borderRadius: "10px",
                            textAlign: "center",
                            fontSize: "80px",
                            marginTop: "-10%",
                          }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          width: "80%",
                          height: "100%",
                          paddingLeft: "45px",
                        }}
                      >
                        <div
                          style={{
                            textAlign: "left",
                            fontSize: "25px",
                            fontFamily: "Bahnschrift",
                            color: "grey",
                          }}
                        >
                          Doctors
                        </div>
                        <div
                          style={{
                            textAlign: "left",
                            fontSize: "50px",
                            fontWeight: "bold",
                            spacing: "5px",
                            fontFamily: "Figtree",
                          }}
                        >
                          {doctors}
                        </div>
                      </div>
                    </div>
                  </CardActionArea>
                </Grid>
                <Grid item md={4}>
                  <CardActionArea
                    sx={[
                      {
                        height: "12vh",
                        borderRadius: "10px",
                        backgroundColor:
                          state.auth.theme == "dark"
                            ? themeStyleDark.primaryCardColor
                            : themeStyle.primaryCardColor,

                        color:
                          state.auth.theme == "dark"
                            ? themeStyleDark.textColor
                            : themeStyle.textColor,
                        transition: "background-color 0.5s, border-color 0.5s",
                      },
                      (theme) => ({
                        "&& .MuiTouchRipple-child": {
                          backgroundColor:
                            state.auth.theme == "dark"
                              ? themeStyleDark.primaryColor
                              : themeStyle.primaryColor,
                        },
                      }),
                    ]}
                    style={{
                      marginBottom: "10px",
                      boxShadow: "0px 0px 10px #ccc",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        height: "100%",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          flexDirection: "column",
                          width: "20%",
                          backgroundColor: "#252525",
                          borderRadius: "10px",
                        }}
                      >
                        <Vaccines
                          style={{
                            padding: "10px",
                            color: "white",
                            border: "2px solid #252525",

                            borderRadius: "10px",
                            textAlign: "center",
                            fontSize: "80px",
                            marginTop: "-10%",
                          }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          width: "80%",
                          height: "100%",
                          paddingLeft: "45px",
                        }}
                      >
                        <div
                          style={{
                            textAlign: "left",
                            fontSize: "25px",
                            fontFamily: "Bahnschrift",
                            color: "grey",
                          }}
                        >
                          Hospitals{" "}
                        </div>
                        <div
                          style={{
                            textAlign: "left",
                            fontSize: "50px",
                            fontWeight: "bold",
                            spacing: "5px",
                            fontFamily: "Figtree",
                          }}
                        >
                          {hospitals}
                        </div>
                      </div>
                    </div>
                  </CardActionArea>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item md={8}>
                  <div
                    style={{
                      backgroundColor:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryCardColor
                          : themeStyle.primaryCardColor,
                      marginTop: "6px",
                      padding: "15px",
                      boxShadow: "0px 0px 10px #ccc",
                      borderRadius: "10px",
                      height: "100%",
                    }}
                  >
                    <Line
                      height={120}
                      width={250}
                      plugins={[zoomPlugin]}
                      data={graphState.dataLine}
                      options={{
                        plugins: {
                          zoom: {
                            zoom: {
                              wheel: {
                                enabled: true,
                              },
                              pinch: {
                                enabled: true,
                              },
                              mode: "xy",
                            },
                          },
                        },
                        legend: {
                          display: false,
                        },
                        color:
                          state.auth.theme == "dark"
                            ? themeStyleDark.primaryColor
                            : themeStyle.primaryColor,
                        backgroundColor:
                          state.auth.theme == "dark"
                            ? themeStyleDark.primaryColor
                            : themeStyle.primaryColor,
                        borderColor:
                          state.auth.theme == "dark"
                            ? themeStyleDark.primaryColor
                            : themeStyle.primaryColor,
                        responsive: true,
                        animation: { duration: 0 },
                        title: {
                          display: true,
                          text: "New user data",
                        },
                      }}
                    />
                  </div>
                </Grid>
                <Grid item md={4}>
                  <div
                    style={{
                      backgroundColor:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryCardColor
                          : themeStyle.primaryCardColor,
                      padding: "20px",
                      borderRadius: "10px",
                      marginTop: "7px",
                      height: "100%",
                      boxShadow: "0px 0px 10px #ccc",
                    }}
                  >
                    <h2
                      style={{
                        marginTop: "5px",
                        color:
                          state.auth.theme == "dark"
                            ? themeStyleDark.textColor
                            : themeStyle.textColor,
                        marginBottom: "35px",
                      }}
                    >
                      Send Notifications
                    </h2>
                    <Grid
                      container
                      spacing={1}
                      style={{ marginTop: "25px", marginBottom: "25px" }}
                    >
                      <Grid item md={4}>
                        {selectedNoti == "EVERYONE" ? (
                          <Button
                            fullWidth
                            value="EVERYONE"
                            onClick={handleNotiSelect}
                            p={2}
                            style={{
                              backgroundColor:
                                state.auth.theme == "dark"
                                  ? themeStyleDark.primaryColor
                                  : themeStyle.primaryColor,
                              color: "white",
                              height: "35px",
                              marginTop: "-15px",
                            }}
                            borderRadius={6}
                          >
                            <h3
                              style={{
                                marginTop: "17px",
                                fontSize: "15px",
                                letterSpacing: "1.5px",
                              }}
                            >
                              EVERYONE
                            </h3>
                          </Button>
                        ) : (
                          <Button
                            fullWidth
                            value="EVERYONE"
                            onClick={handleNotiSelect}
                            p={2}
                            style={{
                              height: "35px",
                              backgroundColor: "white",
                              color: "white",
                              marginTop: "-15px",

                              border:
                                state.auth.theme == "dark"
                                  ? "1px solid " + themeStyleDark.primaryColor
                                  : "1px solid " + themeStyle.primaryColor,
                            }}
                            borderRadius={6}
                          >
                            <h3
                              style={{
                                marginTop: "17px",
                                fontSize: "15px",
                                color:
                                  state.auth.theme == "dark"
                                    ? themeStyleDark.primaryColor
                                    : themeStyle.primaryColor,
                              }}
                            >
                              EVERYONE
                            </h3>
                          </Button>
                        )}
                      </Grid>
                      <Grid item md={4}>
                        {selectedNoti == "PAID USERS" ? (
                          <Button
                            fullWidth
                            value="PAID USERS"
                            onClick={handleNotiSelect}
                            p={2}
                            style={{
                              backgroundColor:
                                state.auth.theme == "dark"
                                  ? themeStyleDark.primaryColor
                                  : themeStyle.primaryColor,
                              color: "white",
                              height: "35px",
                              marginTop: "-15px",
                            }}
                            borderRadius={6}
                          >
                            <h3
                              style={{
                                marginTop: "17px",
                                fontSize: "15px",
                                letterSpacing: "1.5px",
                              }}
                            >
                              PAID USERS
                            </h3>
                          </Button>
                        ) : (
                          <Button
                            fullWidth
                            value="PAID USERS"
                            onClick={handleNotiSelect}
                            p={2}
                            style={{
                              height: "35px",
                              backgroundColor: "white",
                              color: "white",
                              marginTop: "-15px",
                              border:
                                state.auth.theme == "dark"
                                  ? "1px solid " + themeStyleDark.primaryColor
                                  : "1px solid " + themeStyle.primaryColor,
                            }}
                            borderRadius={6}
                          >
                            <h3
                              style={{
                                marginTop: "17px",
                                fontSize: "15px",
                                color:
                                  state.auth.theme == "dark"
                                    ? themeStyleDark.primaryColor
                                    : themeStyle.primaryColor,
                              }}
                            >
                              PAID USERS
                            </h3>
                          </Button>
                        )}
                      </Grid>
                      <Grid item md={4}>
                        {selectedNoti == "FREE USERS" ? (
                          <Button
                            fullWidth
                            value="FREE USERS"
                            onClick={handleNotiSelect}
                            p={2}
                            style={{
                              backgroundColor:
                                state.auth.theme == "dark"
                                  ? themeStyleDark.primaryColor
                                  : themeStyle.primaryColor,
                              color: "white",
                              height: "35px",
                              marginTop: "-15px",
                            }}
                            borderRadius={6}
                          >
                            <h3
                              style={{
                                marginTop: "17px",
                                fontSize: "15px",
                                letterSpacing: "1.5px",
                              }}
                            >
                              FREE USERS{" "}
                            </h3>
                          </Button>
                        ) : (
                          <Button
                            fullWidth
                            value="FREE USERS"
                            onClick={handleNotiSelect}
                            p={2}
                            style={{
                              height: "35px",
                              backgroundColor: "white",
                              color: "white",
                              marginTop: "-15px",
                              border:
                                state.auth.theme == "dark"
                                  ? "1px solid " + themeStyleDark.primaryColor
                                  : "1px solid " + themeStyle.primaryColor,
                            }}
                            borderRadius={6}
                          >
                            <h3
                              style={{
                                marginTop: "17px",
                                fontSize: "15px",
                                color:
                                  state.auth.theme == "dark"
                                    ? themeStyleDark.primaryColor
                                    : themeStyle.primaryColor,
                              }}
                            >
                              FREE USERS{" "}
                            </h3>
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      spacing={1}
                      style={{ marginTop: "25px", marginBottom: "25px" }}
                    >
                      <Grid item md={4}>
                        {selectedNoti == "REPS" ? (
                          <Button
                            fullWidth
                            value="REPS"
                            onClick={handleNotiSelect}
                            p={2}
                            style={{
                              backgroundColor:
                                state.auth.theme == "dark"
                                  ? themeStyleDark.primaryColor
                                  : themeStyle.primaryColor,
                              color: "white",
                              height: "35px",
                              marginTop: "-15px",
                            }}
                            borderRadius={6}
                          >
                            <h3
                              style={{
                                marginTop: "17px",
                                fontSize: "15px",
                                letterSpacing: "1.5px",
                              }}
                            >
                              REPS
                            </h3>
                          </Button>
                        ) : (
                          <Button
                            fullWidth
                            value="REPS"
                            onClick={handleNotiSelect}
                            p={2}
                            style={{
                              height: "35px",
                              backgroundColor: "white",
                              color: "white",
                              marginTop: "-15px",

                              border:
                                state.auth.theme == "dark"
                                  ? "1px solid " + themeStyleDark.primaryColor
                                  : "1px solid " + themeStyle.primaryColor,
                            }}
                            borderRadius={6}
                          >
                            <h3
                              style={{
                                marginTop: "17px",
                                fontSize: "15px",
                                color:
                                  state.auth.theme == "dark"
                                    ? themeStyleDark.primaryColor
                                    : themeStyle.primaryColor,
                              }}
                            >
                              REPS
                            </h3>
                          </Button>
                        )}
                      </Grid>
                      <Grid item md={4}>
                        {selectedNoti == "DOCTORS" ? (
                          <Button
                            fullWidth
                            value="DOCTORS"
                            onClick={handleNotiSelect}
                            p={2}
                            style={{
                              backgroundColor:
                                state.auth.theme == "dark"
                                  ? themeStyleDark.primaryColor
                                  : themeStyle.primaryColor,
                              color: "white",
                              height: "35px",
                              marginTop: "-15px",
                            }}
                            borderRadius={6}
                          >
                            <h3
                              style={{
                                marginTop: "17px",
                                fontSize: "15px",
                                letterSpacing: "1.5px",
                              }}
                            >
                              DOCTORS
                            </h3>
                          </Button>
                        ) : (
                          <Button
                            fullWidth
                            value="DOCTORS"
                            onClick={handleNotiSelect}
                            p={2}
                            style={{
                              height: "35px",
                              backgroundColor: "white",
                              color: "white",
                              marginTop: "-15px",

                              border:
                                state.auth.theme == "dark"
                                  ? "1px solid " + themeStyleDark.primaryColor
                                  : "1px solid " + themeStyle.primaryColor,
                            }}
                            borderRadius={6}
                          >
                            <h3
                              style={{
                                marginTop: "17px",
                                fontSize: "15px",
                                color:
                                  state.auth.theme == "dark"
                                    ? themeStyleDark.primaryColor
                                    : themeStyle.primaryColor,
                              }}
                            >
                              DOCTORS
                            </h3>
                          </Button>
                        )}
                      </Grid>
                      <Grid item md={4}>
                        {selectedNoti == "HOSPITALS" ? (
                          <Button
                            fullWidth
                            value="HOSPITALS"
                            onClick={handleNotiSelect}
                            p={2}
                            style={{
                              backgroundColor:
                                state.auth.theme == "dark"
                                  ? themeStyleDark.primaryColor
                                  : themeStyle.primaryColor,
                              color: "white",
                              height: "35px",
                              marginTop: "-15px",
                            }}
                            borderRadius={6}
                          >
                            <h3
                              style={{
                                marginTop: "17px",
                                fontSize: "15px",
                                letterSpacing: "1.5px",
                              }}
                            >
                              HOSPITALS
                            </h3>
                          </Button>
                        ) : (
                          <Button
                            fullWidth
                            value="HOSPITALS"
                            onClick={handleNotiSelect}
                            p={2}
                            style={{
                              height: "35px",
                              backgroundColor: "white",
                              color: "white",
                              marginTop: "-15px",

                              border:
                                state.auth.theme == "dark"
                                  ? "1px solid " + themeStyleDark.primaryColor
                                  : "1px solid " + themeStyle.primaryColor,
                            }}
                            borderRadius={6}
                          >
                            <h3
                              style={{
                                marginTop: "17px",
                                fontSize: "15px",
                                color:
                                  state.auth.theme == "dark"
                                    ? themeStyleDark.primaryColor
                                    : themeStyle.primaryColor,
                              }}
                            >
                              HOSPITALS
                            </h3>
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                    <br></br>
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      placeholder="Subject"
                      variant="outlined"
                      value={subject}
                      size="small"
                      onChange={(e) => setSubject(e.target.value)}
                    />
                    <br></br> <br></br>
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      placeholder="Message"
                      variant="outlined"
                      size="small"
                      style={{
                        marginBottom: "25px",
                      }}
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                    <br></br> <br></br>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Button
                        style={{
                          fontSize: "20px",
                          marginTop: "-5px",
                          height: "35px",
                          backgroundColor:
                            state.auth.theme == "dark"
                              ? themeStyleDark.primaryColor
                              : themeStyle.primaryColor,
                        }}
                        onClick={() => sendNotification()}
                        variant="contained"
                        color="primary"
                      >
                        <span
                          style={{
                            color: "white",
                          }}
                        >
                          Send
                        </span>
                        <ChevronRightIcon
                          style={{
                            fontSize: "30px",
                            color: "white",
                            marginRight: "-10px",
                          }}
                        />
                      </Button>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Fragment>
        );
      }
    } else {
      return <Navigate to="/login" />;
    }
  } else {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          backgroundColor:
            state.auth.theme == "dark"
              ? themeStyleDark.backgroundColor
              : themeStyle.backgroundColor,
          marginTop: "46vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
}

export default Dashboard;
