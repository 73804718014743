export const themeStyleDark = {
  backgroundColor: "#F4F5FA",
  primaryColor: "#03A9F4",
  primaryCardColor: "white",
  textColor: "black",
  primaryButtonColor: "#03A9F4",
  primaryButtonTextColor: "white",
  secondaryButtonColor: "",
};

export const themeStyle = {
  backgroundColor: "#F4F5FA",
  primaryColor: "#03A9F4",
  primaryCardColor: "white",
  textColor: "black",
  primaryButtonColor: "#03A9F4",
  primaryButtonTextColor: "black",
  secondaryButtonColor: "",
};
